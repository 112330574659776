<template>
	<NuxtLink
		:to="url || to"
		:target="target"
		class="link-wrap"
		v-if="!customType"
	>
		<slot />
	</NuxtLink>
	<NuxtLink
		:to="url || to"
		:target="target"
		class="link-wrap"
		v-else
		custom
		v-slot="{ navigate }"
	>
		<component
			:is="customType"
			@click="navigate"
			v-bind="$attrs"
			class="link-wrap"
		>
			<slot></slot>
		</component>
	</NuxtLink>
</template>

<script>
import LinkMixin from "./LinkMixin.js";

export default defineNuxtComponent({
	name: "LinkWrap",
	props: {
		href: {
			type: String,
			default: null,
		},
		to: {
			type: String,
		},
		openInNewWindow: {
			type: Boolean,
			default: false,
		},
		customType: {
			type: String,
		},
	},

	mixins: [LinkMixin],
});
</script>

<style lang="scss" scoped>
.link-wrap {
	text-decoration: none;
	color: inherit;
	display: inline-block;
	cursor: pointer;

	&:focus-visible {
		border-radius: 4px;
		outline: 2px solid $color-cta-focus;
		outline-offset: 4px;
	}
}
</style>
